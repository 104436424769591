import React, { useState } from 'react'
import { ModalComponent, ButtonComponent, ParagraphComponent } from '../';
import { withTranslation } from 'react-i18next';

import './footer.style.scss'
import logo from '../../assets/images/logo-white.png';
import i18next, { changeLanguage } from 'i18next';
import i18n from '../../i18n';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';
import yt from '../../assets/icons/YouTube.svg';
import wa from '../../assets/icons/whatsapp.svg';
import tg from '../../assets/icons/telegram.svg';


function FooterComponent({ t }: { t: any }) {

  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function changePageLanguage() {
    if (getCookieConsentValue()?.toString() === "true") { localStorage.setItem("lng", i18n.resolvedLanguage) }
    navigate("/" + i18n.resolvedLanguage + t('navbar.routes.home'))

  }

  return (
    <footer className='footer'>
      <ModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} />
      <div className='copyrightDiv'>
        {/* <ParagraphComponent white>Terms and Conditions</ParagraphComponent> */}
        <ParagraphComponent white><a href="https://tesenergy.hr/assets/pdfs/privacy.pdf">{t('footer.copyright.privacy')}</a></ParagraphComponent>
        <ParagraphComponent white>{t('footer.copyright.copyright')}</ParagraphComponent>
        <div className="socials">
          <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@TESEnergy' ><img src={yt} alt="YouTube Logo" /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://wa.me/385981713568"><img src={wa} alt="WhatsApp Logo" /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://t.me/TESEnergy"><img src={tg} alt="Telegram Logo" /></a>
        </div>
      </div>
      <div className='linksDiv'>
        <div className="linksDivTitle">
          <h3><ParagraphComponent style={{ marginBottom: '0.75rem' }} margins>{t('footer.links')}</ParagraphComponent></h3>
        </div>
        <div className="homeLinks">
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.home')}>{t('navbar.mainLinks.home')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.about')}>{t('navbar.mainLinks.about')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <a onClick={() => setOpenModal(true)}>{t('navbar.mainLinks.contact')}</a> </ParagraphComponent>
        </div>
        <div className="productsLinks">
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment')}>{t('navbar.dropdownLinks.products.equipment')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers')}>{t('navbar.dropdownLinks.products.chargers')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.heatPumps')}>{t('navbar.dropdownLinks.services.heatPumps')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.mountingSystems')}>{t('navbar.dropdownLinks.services.mountingSystems')}</Link> </ParagraphComponent>
        </div>
        <div className="servicesLinks">
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.solar')}>{t('navbar.dropdownLinks.services.solar')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.software')}>{t('navbar.dropdownLinks.services.software')}</Link> </ParagraphComponent>
          <ParagraphComponent margins white link> <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.references')}>{t('navbar.dropdownLinks.services.references')}</Link> </ParagraphComponent>
        </div>
      </div>
      <div className='contactDiv'>
        <h3><ParagraphComponent style={{ marginBottom: '0.75rem' }} margins>{t('footer.contact.title')}</ParagraphComponent></h3>
        <h4><ParagraphComponent margins white link>{t('footer.contact.tel.tel')} <a href="tel:+385(52)846880">{t('footer.contact.tel.number')}</a></ParagraphComponent></h4>
        <h4><ParagraphComponent margins white link>{t('footer.contact.mob.mob')} <a href="tel:385(99)2660100">{t('footer.contact.mob.number')}</a></ParagraphComponent></h4>
        <h4><ParagraphComponent margins white link>{t('footer.contact.email.email')} <a href="mailto:info@tesenergy.hr">{t('footer.contact.email.emailInfo')}</a></ParagraphComponent></h4>
        <ParagraphComponent margins white>{t('footer.contact.oib')}</ParagraphComponent>
      </div>
      <div className='logoDiv'>
        <img className='logo' src={logo} alt="" />
      </div>
      <div className='addressDiv'>
        <ParagraphComponent style={{ marginBottom: '0.75rem' }} margins>{t('footer.address.title')}</ParagraphComponent>
        <ParagraphComponent margins white>{t('footer.address.addressInfo.1')} </ParagraphComponent>
        <ParagraphComponent margins white>{t('footer.address.addressInfo.2')}</ParagraphComponent>
        <ParagraphComponent margins white>{t('footer.address.addressInfo.3')}</ParagraphComponent>
        <ParagraphComponent margins white>{t('footer.address.addressInfo.4')}</ParagraphComponent>
      </div>
      <div className='languageDiv'>
        <ButtonComponent mini onClick={() => { changeLanguage('hr'); changePageLanguage(); }}>HRV</ButtonComponent>
        <ButtonComponent mini
          onClick={() => { changeLanguage('en'); changePageLanguage(); }}
        >ENG</ButtonComponent>
        {/* <ButtonComponent mini>ITA</ButtonComponent> */}
      </div>
    </footer>
  )
}
export default withTranslation()(FooterComponent);