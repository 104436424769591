import { responseDataType } from './searchBar.component'
import searchIconDark from '../../assets/icons/searchIconDark.svg';
import { ParagraphComponent } from '../typography';
import { decode } from 'html-entities';
import { useNavigate } from 'react-router';
import i18n from '../../i18n';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import Categories, { CategoryEquipment, convertChargerCategoryLink } from '../../pages/equipment/categories';
import { searchProductType } from '../../store/searchStore';
import imagePlaceholder from '../../assets/images/imagePlaceholder.svg';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
    result: searchProductType,
    clearSearch: () => void
}
function SearchResultComponent(props: Props) {
    const { t, i18n } = useTranslation();
    const { result, clearSearch } = props
    const navigate = useNavigate();

    let categoriesEquipment: CategoryEquipment = t('solarEquipment.categories')

    function renderCategory(currentCategory: string) {
        if (currentCategory as keyof CategoryEquipment) {
            return (categoriesEquipment[currentCategory as keyof CategoryEquipment]);
        }
        else return ('');
    }

    return <div className='search-result' onClick={(e) => { navigate("/" + i18n.resolvedLanguage + t('navbar.routes.equipment') + "/" + result.ref); clearSearch() }} key={result.id}>
        <div className="search-result-item">
            <div className="result-image">
                <img src={result.image === '' ? imagePlaceholder : result.image} alt={`Product image of ${result.title}`} />
            </div>
            <div className="result-content">

                <ParagraphComponent bold white>{result.title}</ParagraphComponent>
                <h3><ParagraphComponent className="ref">{t('productsCard.ref')} {result.ref}</ParagraphComponent></h3>
                <ParagraphComponent>{result.category &&
                    <NavLink onClick={(e) => e.stopPropagation()} to={"/" + i18n.resolvedLanguage + convertChargerCategoryLink(result.category ? result.category : '') + "/" + t(`solarEquipment.urlCategories.${result.category}`)}>{result.category && renderCategory(result.category)} </NavLink>} - {result.brand}</ParagraphComponent>
            </div>

        </div>
    </div>
}

export default SearchResultComponent