import ButtonComponent from '../button/button.component'
import { ParagraphComponent, SubtitleComponent } from '../typography'
import './contactWidget.style.scss'
import pula1 from '../../assets/images/references/pula1.webp';
import closeMenu from '../../assets/icons/closeMenu.png';
import callIcon from '../../assets/icons/callIcon.png';
import mailICon from '../../assets/icons/mailIcon.png';
import { useEffect, useState } from 'react';
import ModalComponent from '../modal/modal.component';
import { useTranslation } from 'react-i18next';

function ContactWidgetComponent() {
  const { t, i18n } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const handleMouseMove = (event: MouseEvent) => {
      // if (event.clientY < 40) {
      if (event.clientY < 40 && event.clientX < 300) {        
        setIsVisible(true);
        document.removeEventListener("mousemove", handleMouseMove);
      }
    };


    timeoutId = setTimeout(() => {
      document.addEventListener("mousemove", handleMouseMove);
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className={`contactWidget ${isVisible ? "visible" : "hidden"}`}>
      <ModalComponent openModal={openModal} closeModal={() => setOpenModal(false)} />

      <div className="closeButton" onClick={() => { setIsVisible(false) }}>
        <img src={closeMenu} alt="close button" />
      </div>
      <div className="imgDiv">
        <img src={pula1} alt="klic icon" />
        <div className="imgDivOverlay">

        </div>
        <div className="titleDiv">
          <SubtitleComponent white bold>{t('contactWidget.assistance')}</SubtitleComponent>
          <ParagraphComponent white medium>{t('contactWidget.reachOut')}</ParagraphComponent>
        </div>
      </div>

      <div className="buttonsDiv">
        <ButtonComponent rounded >
          <img src={callIcon} alt="Call button" />
          <a href="mob:+385(98)1713568">{t('contactWidget.callUs')}</a>
        </ButtonComponent>
        <ButtonComponent rounded onClick={() => { setOpenModal(true) }}>
          <img src={mailICon} alt="Email cion" />{t('contactWidget.messageUs')}</ButtonComponent>
      </div>
    </div>
  )
}

export default ContactWidgetComponent