import { ButtonComponent, CardComponent, ContainerComponent, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import './solar.style.scss';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from '../../i18n';

//@ts-ignore
import installation from '../../assets/images/installation.webp'
//@ts-ignore
import solarCarport from '../../assets/images/solarCarport.webp'

//@ts-ignore
import roof1crimmpi from '../../assets/images/references/roof1crimmpi.webp';


function Solar({ t }: { t: any }) {

    return (
        <div className='solar'>
            <Helmet>
                <title>{t('solarPlants.meta.title')}</title>
                <meta name="description" content={t('solarPlants.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <Trans>
                    <h1><TitleComponent centered smaller>{t('solarPlants.title')}</TitleComponent></h1>
                    <div className="solarText">
                        <SubtitleComponent max>{t('solarPlants.landingText1')}</SubtitleComponent>
                        <SubtitleComponent max>{t('solarPlants.landingText2')}</SubtitleComponent>
                    </div>
                    <div className="installationDiv">
                        <SubtitleComponent centered white bold>{t('solarPlants.landingSubtitle')}</SubtitleComponent>
                        <img src={installation} alt={t('solarPlants.alt')} />
                    </div>
                    <h2><SubtitleComponent style={{ marginTop: '5rem' }} centered white bold large>{t('solarPlants.products')}</SubtitleComponent></h2>
                    <div className="productsLink">
                        <CardComponent>
                            <div className='items'>
                                <div className="item">
                                    <div className="titleEquipment">
                                        <h2 className='title'>
                                            <SubtitleComponent white bold centered><span>{t('home.equipment.title')}</span></SubtitleComponent>
                                        </h2>
                                        <h3 className='description'><ParagraphComponent style={{ textAlign: "center", marginTop: "1rem" }} >{t('home.equipment.subtitle')}</ParagraphComponent></h3>
                                    </div>
                                    <div className="equipmentButton">
                                        <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.equipment')}><ButtonComponent>{t('misc.learnMore')}</ButtonComponent></Link>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="titleEquipment">
                                        <h2 className='title'>
                                            <SubtitleComponent white bold centered><span>{t('home.chargers.title')}</span></SubtitleComponent>
                                        </h2>
                                        <h3 className='description'><ParagraphComponent style={{ textAlign: "center", marginTop: "1rem" }} >{t('home.chargers.subtitle')}</ParagraphComponent></h3>
                                    </div>
                                    <div className="equipmentButton">
                                        <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.chargers')}><ButtonComponent>{t('misc.learnMore')}</ButtonComponent></Link>
                                    </div>
                                </div>
                            </div>
                        </CardComponent>
                    </div>

                    <div className="carportImg">
                        <h2><SubtitleComponent style={{ marginTop: '5rem' }} centered white bold large>{t('solarPlants.references')}</SubtitleComponent></h2>
                        <img src={roof1crimmpi} alt="Solar Plant Project" />
                        <h3 className='description'><SubtitleComponent style={{ textAlign: "center", marginTop: "2rem" }} >{t('solarPlants.referencesSubtitle')}</SubtitleComponent></h3>
                        <Link to={"/" + i18n.resolvedLanguage + t('navbar.routes.references')}><ButtonComponent>{t('solarPlants.referencesButton')}</ButtonComponent></Link>
                    </div>
                    <h2><SubtitleComponent style={{ marginTop: '5rem' }} centered white bold large>{t('solarPlants.schemes.schemeTitle')}</SubtitleComponent></h2>
                    <div className="schemeContainer">
                        <div className="schemeImage"><img src={process.env.PUBLIC_URL + `/images/${t('solarPlants.schemes.on.imgName')}.svg`} alt={t('solarPlants.schemes.on.alt')} /></div>
                        <div className="schemeDescription">
                            <h3><SubtitleComponent bold centered white>{t('solarPlants.schemes.on.subtitle')}</SubtitleComponent></h3>
                            <ParagraphComponent>{t('solarPlants.schemes.on.text')}</ParagraphComponent>
                        </div>
                        <div className="schemeImage1"><img src={process.env.PUBLIC_URL + `/images/${t('solarPlants.schemes.off.imgName')}.svg`} alt={t('solarPlants.schemes.off.alt')} /></div>
                        <div className="schemeDescription1">
                            <h3><SubtitleComponent bold centered white>{t('solarPlants.schemes.off.subtitle')}</SubtitleComponent></h3>
                            <ParagraphComponent>{t('solarPlants.schemes.off.text')}</ParagraphComponent>
                        </div>
                    </div>
                    <div className="hybridContainer">
                        <h3><SubtitleComponent bold centered white className="schemeTitle">{t('solarPlants.schemes.hybrid.subtitle')}</SubtitleComponent></h3>
                        <img src={process.env.PUBLIC_URL + `/images/${t('solarPlants.schemes.hybrid.imgName')}.svg`} className="schemeImage" alt={t('solarPlants.schemes.hybrid.alt')} />
                        <ParagraphComponent className="schemeDescription">{t('solarPlants.schemes.hybrid.text')}</ParagraphComponent>
                    </div>
                </Trans>
            </ContainerComponent>
        </div >
    )
}

export default withTranslation()(Solar);
