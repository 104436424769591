import { Helmet } from 'react-helmet';
import './references.style.scss'
import { ContainerComponent, ImageSwiper, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import { Trans, useTranslation } from 'react-i18next';


import a1 from '../../assets/images/references/projekt2/a1.webp';

import a2 from '../../assets/images/references/projekt2/a2.webp';

import a3 from '../../assets/images/references/projekt2/a3.webp';

import a4 from '../../assets/images/references/projekt2/a4.webp';

import a5 from '../../assets/images/references/projekt2/a5.webp';

import a6 from '../../assets/images/references/projekt2/a6.webp';

import a7 from '../../assets/images/references/projekt2/a7.webp';

import a8 from '../../assets/images/references/projekt2/a8.webp';

import ReactPlayer from 'react-player';


function ReferenceABS() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="referencePlinara" >
                <Helmet>
                    <title>{t('project2.meta.title')}</title>
                    <meta name="description" content={t('project2.meta.description')} />
                </Helmet>
                <ContainerComponent>
                    <Trans>
                        <h1><TitleComponent centered smaller style={{ marginTop: '3rem' }}>{t('project2.title')}</TitleComponent></h1>
                        
                        <h3><SubtitleComponent>{t('project2.subtitle')}</SubtitleComponent></h3>
                        <ReactPlayer className="videoPlayer" playing={true} controls={true} autoPlay muted={true} url={process.env.PUBLIC_URL + '/videos/Projekt2.mp4'} />

                        <div className="specifications">
                            <h3><SubtitleComponent medium white large semiBold>{t('project2.specifications.title')}</SubtitleComponent></h3>
                            <ParagraphComponent>{t('project2.specifications.content')}</ParagraphComponent>
                        </div>
                        <ImageSwiper images={[a1, a2, a3, a4, a5, a6, a7, a8]} thumbs />
                    </Trans>
                </ContainerComponent>
            </div>
        </>
    )
}

export default ReferenceABS