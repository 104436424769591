import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next';
import { ContainerComponent, ImageSwiper, ParagraphComponent, SubtitleComponent, TitleComponent } from '../../components';
import '../diagnostics/diagnostics.style.scss'
import rental1 from '../../assets/images/references/rental1.webp'
import rental2 from '../../assets/images/references/rental2.webp'
import rental3 from '../../assets/images/references/rental3.webp'
import rental5 from '../../assets/images/references/rental5.webp'
import rental7 from '../../assets/images/references/rental7.webp'
import rental8 from '../../assets/images/references/rental8.webp'
import ReactPlayer from 'react-player';
function Rental() {
    const { t, i18n } = useTranslation();

    return (
        <div className='rental diagnostics'>
            <Helmet>
                <title>{t('rental.meta.title')}</title>
                <meta name="description" content={t('rental.meta.description')} />
            </Helmet>
            <ContainerComponent>
                <Trans>

                    <h1><TitleComponent centered smaller>{t('rental.title')}</TitleComponent></h1>
                    <div className="diagnosticsIntroText">
                        <h2><SubtitleComponent white semiBold large>{t('rental.crawler1.title')}</SubtitleComponent></h2>
                        <ReactPlayer className="videoPlayer" playing={true} controls={true}  muted={true} url={process.env.PUBLIC_URL + '/videos/Busenje.mp4'}/>

                        <h3><ParagraphComponent >{t('rental.crawler1.subtitle')}</ParagraphComponent></h3>

                        <ImageSwiper thumbs images={[rental7, rental8]} />

                        <h2><SubtitleComponent white semiBold large style={{ marginTop: '4rem' }}>{t('rental.crawler2.title')}</SubtitleComponent></h2>
                        <ReactPlayer className="videoPlayer" playing={true} controls={true} autoPlay muted={true} url={process.env.PUBLIC_URL + '/videos/Spider.mp4'} />

                        <h3><ParagraphComponent >{t('rental.crawler2.subtitle')}</ParagraphComponent></h3>
                    </div>

                    <ImageSwiper thumbs images={[rental2, rental3, rental5, rental1]} />
                </Trans>
            </ContainerComponent>
        </div>
    )
}

export default Rental